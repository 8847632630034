.footer-container {
    background-color: #fff100;
    padding: 40px 0;
    color: #555; /* Neutral gray for general text */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1000px;
    margin-bottom: 40px;
}

.contact-section {
    text-align: center;
    margin-top: 3rem;
}

.contact-section h2 {
    font-size: 2rem;
    color: #1e4a7b; /* Dark blue for headings */
}

.contact-section p {
    font-size: 1rem;
    color: #666; /* Medium gray for paragraph text */
}

.contact-section a {
    color: #1e4a7b;
    text-decoration: none;
}

.contact-section a:hover {
    text-decoration: underline;
}

.footer-link-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.footer-link-items {
    flex: 1;
    margin: 0 20px;
    text-align: left;
}

.footer-link-items h2 {
    margin-bottom: 16px;
    font-size: 20px;
    color: #1e4a7b; /* Use the same dark blue for link section headers */
}

.footer-link-items p,
.footer-link-items a {
    margin-bottom: 10px;
    color: #7f8c8d ; /* Softer gray for paragraph and link text */
    text-decoration: none;
    font-size: 16px;
}

.footer-link-items a:hover {
    color: #2980b9; /* Slightly brighter blue for hover effects */
    transition: 0.3s ease;
}

.social-media {
    width: 100%;
    padding: 20px 0;
    background-color: #222;
}

.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1000px;
    margin: auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    width: 120px;
}

.social-icon-link {
    color: #e0e071; /* Yellowish icons for contrast */
    font-size: 24px;
}

.social-icon-link:hover {
    color: #fff; /* Hover effect to change to white */
    transition: 0.3s ease;
}

.website-rights {
    color: #7f8c8d ; /* Neutral gray for copyright text */
    margin: auto 0;
    font-size: 14px;
}

.footer-bottom {
    background-color: #222; /* Dark footer bottom for better contrast */
    padding: 10px 0;
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 820px) {
    .footer-links {
      flex-direction: column;
      align-items: center;
    }

    .footer-link-wrapper {
      flex-direction: column;
    }

    .social-media-wrap {
      flex-direction: column;
      text-align: center;
    }

    .social-icons {
      margin-top: 20px;
    }
}
