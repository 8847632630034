.services-page {
    padding: 2rem;
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;
  }
  
  .services-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .services-title {
    font-size: 3rem;
    color: #2c3e50;
  }
  
  .services-subtitle {
    font-size: 1.2rem;
    color: #7f8c8d;
    margin-top: 0.5rem;
  }
  
  .services-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 2rem 0;
  }
  
  .service-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .service-card:hover {
    transform: scale(1.05);
  }
  
  .service-card h2 {
    font-size: 1.5rem;
    color: #16a085;
    margin-bottom: 1rem;
  }
  
  .service-card p {
    color: #7f8c8d;
    line-height: 1.6;
  }
  
  .contact-section {
    text-align: center;
    margin-top: 3rem;
  }
  
  .contact-section h2 {
    font-size: 2rem;
    color: #2980b9;
  }
  
  .contact-section p {
    font-size: 1rem;
    color: #7f8c8d;
  }
  
  .contact-section a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .contact-section a:hover {
    text-decoration: underline;
  }

  
  /* Ensure this is applied specifically to paragraphs inside card items */
.cards__item__paragraph {
    text-decoration: none !important; /* Ensure no underline */
    font-size: 14px;
    color: #6c757d;
    margin-top: 8px;
    line-height: 1.5;
    /* Add additional styling as needed */
  }
  
  /* Ensuring no underlines globally or affecting the paragraph */
  p {
    text-decoration: none !important;
  }
  