
:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }

  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .btn--medium {
    padding: 8px 20px;
    font-size: 20px;
  }

  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }

  .btn--medium:hover, .btn--large:hover {
    background-color: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
  }



  /* Navbar */

  /* logo image  */

  .navbar-logo img {
    height: 100px;  /* Adjust height to fit the navbar */
    width: 100%;   /* Ensure it's square */
    object-fit: cover; /* Make sure the image scales properly */
    border-radius: 0%; /* Make the image round */
    margin-left: 10px; /* Optional: Add space between text and logo */
    transition: all 0.3s ease; /* Add smooth transition on hover */
  }
  
  .navbar-logo img:hover {
    transform: scale(1.1); /* Optional: Slight zoom effect on hover */
  }


  
  .navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  /* background: #e0e071;   */
  background: #fff100;
  height: 80px;
  display: flex;
  /* justify-content: center; */
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 0 20px; /* Add padding for mobile responsiveness */
}
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  width: 100%;
}


.nav-menu { 
  display: flex; 
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center; 
  justify-content: flex-end;  
  margin-right: 20px;
}

.nav-menu.active {
  /* background: #242222; */
  left: 0;
  opacity: 1;
  /* transition: all 0.5s ease;
  z-index: 1; */
}

.navbar-logo {
  color: #fff;
  /* justify-self: start; */
  top: 0;
  left: 0;
  /* margin-left: -10px;
  margin-top: -50px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.logo-img {
  height: 60px;  /* Adjust height to fit the navbar */
  width: auto;   /* Maintain aspect ratio */
  object-fit: contain; /* Ensure image scales   properly */
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}


.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* Mobile Styles */
@media screen and (max-width: 960px) {

  .navbar-logo {
    color: #fff;
    margin-left: -10px;
    margin-top: -50px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .NavbarItems {
    position: absolute;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    /* left: -100%; */
    left: 0;
    /* opacity: 1; */
    opacity: 0;
    transition: all 0.5s ease;

    z-index: 1; /* Stack on top */
    background: #242222;
  }

  .nav-menu.active {
    /* background: #242222; */
    left: 0;
    opacity: 1;
    /* transition: all 0.5s ease;
    z-index: 1; */
  }

  .nav-links {
    text-align: center;
    /* padding: 2rem; */
    padding: 1rem;
    width: 100%;
    /* display: table; */
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    /* position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer; */
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    /* color: #242424; */
    color: #FFFF00;
    transition: 250ms;
  }
}

  /* end Navbar */

  /* Hero section css */
  /* video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  } */

  .hero-container {
    
    /* background: url('/src/images/carosel-gp.jpeg')
    center center no-repeat; */
    /* background: url('/images/img-home.jpg') 
    center center/cover no-repeat; */
    /* height: 800px;
    width: 100%; */
    height: 600px;
    width: 100%;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }

  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }

  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

  }

  .hero-btns {
    margin-top: 32px;    
  }

  .hero-btns .btn {
    margin: 6px;
  }

  .carousel-inner>.carousel-item>img, .carousel-inner>.carousel-item>a>img {
    display: block;
    height: 650px;
    /* height: auto; */
    max-width: 100%;
    line-height: 1;
    margin:auto;
    width: 100%;
    }

  .fa-play-circle {
    margin-left: 4px;
  }

  @media screen and (max-width: 768px) {
    .hero-container {
      /* background: url('/src/images/carosel-transformed.jpeg'); */
        background-size: cover; /* Ensures the image fits without cutting on smaller screens */
        /* width: 960px;
        height: 800px; */
    }
}

  @media screen and (max-width: 960px) {
    .hero-container > h1 {     
      font-size: 70px;
      margin-top: -150px;
    }

    @media  screen and (max-width: 768px) {
      .hero-container > h1 {      
        font-size: 50px;
        margin-top: -100px;
      }      
    }

    .hero-container > p {
      font-size: 30px;
    }

    .btn-mobile {
      display: block;
      text-decoration: none;
    }
    .btn {
      width: 100%;
    }
    
  }

  /* End hero section */


  /* cards section */
  .cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__item__paragraph {
    text-decoration: none !important; /* Ensure no underline */
    font-size: 14px;
    color: #6c757d;
    margin-top: 8px;
    line-height: 1.5;
    /* Add additional styling as needed */
  }

  .cards__paragraphs {
    margin-top: 2rem;
    text-align: center;
  }
  
  .cards__paragraphs p {
    margin: 1rem 0;
    font-size: 16px;
    line-height: 1.6;
    color: #252e48;
  }
  
  
  /* Ensuring no underlines globally or affecting the paragraph */
  p {
    text-decoration: none !important;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }

  /* End cards section */


  /* Footer css */

  /*
  .footer-container {
    background-color: #e0e071;
    /* padding: 4rem 0 2rem 0; 
    padding: 1rem 0;
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  */

  .footer-container {
    background-color: #fff100;
    padding: 1rem 0; /* Reduce padding */
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-container * {
    margin: 0; /* Reset margins for all child elements */
}

  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }

  .footer-suscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }

  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }

  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }

  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }

  .footer-link-wrapper {
    display: flex;
  }

  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }

  .footer-link-items > h2 {
    margin: 16px;
  }

  .footer-link-items > h2 {
    color: #fff;
  }

  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
  }

  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: .3s ease-out;
  }

  .footer-email-form h2 {
    margin-bottom: 32px;
  }

  .footer-input::placeholder {
    color: #b1b1b1;
  }

  /* social icons */
  .social-icons-link {
    color: #fff;
    font-size: 24px;
  }

  .social-media {
    max-width: 1000px;
    width: 100%;
  }

  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;

  }
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }

  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
  }

  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }

  @media screen  and (max-width: 820px){
    .footer-links {
      padding-top: 2rem;
    }
    .footer-input {
      width: 100%;
    }
    .btn {
      width: 100%;
    }

    .footer-link-wrapper {
      flex-direction: column;
    }

    .social-media-wrap {
      flex-direction: column;
    }
    
  }

  /* End footer css */